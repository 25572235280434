import React from 'react';
import { Modal } from 'react-bootstrap';
import { homeActionsModal } from '../../utils/home-actions';
import { ButtonAction } from '../ButtonAction';
import useAuth from '~/hooks/useAuth';

export const ModalMoreAct: React.FC<{ open: boolean; onClose: () => void }> = ({
  open,
  onClose,
}) => {
  const user = useAuth();

  return (
    <Modal show={open} onHide={onClose} centered>
      <Modal.Header>
        <Modal.Title>Mais ações</Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{ display: 'flex', flexDirection: 'column', gap: '0.9375rem' }}
      >
        {homeActionsModal.map((action) => (
          <ButtonAction
            key={action.label}
            label={action.label}
            href={action.href}
            icon={action.icon}
            codTela={action.codTela}
            user={user}
            buttonDestaque={action.buttonDestaque}
            onClick={onClose}
            customBgColor={action.customBgColor || 'white'}
          />
        ))}
      </Modal.Body>
    </Modal>
  );
};
