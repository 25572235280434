import React, { ReactNode } from 'react';
import { DashCardContainer } from './styles';

interface CardProps {
  title?: string;
  topChildren?: ReactNode;
  topRightButton?: ReactNode;
  topChildrenMiddle?: boolean;
  body: ReactNode;
  bgColor?: string;
}

export const Card: React.FC<CardProps> = ({
  title = '',
  topChildren,
  topRightButton,
  topChildrenMiddle = true,
  body,
  bgColor = 'white',
}) => {
  return (
    <DashCardContainer bgColor={bgColor}>
      {(title || topRightButton || topChildren) && (
        <div className="card-header">
          <div className="card-header-content">
            {title && (
              <h5
                className="card-header-title"
                style={{
                  color: bgColor !== 'white' ? 'white' : undefined,
                }}
              >
                {title}
              </h5>
            )}
            {topChildren && topChildrenMiddle && <div>{topChildren}</div>}
            {topRightButton && <div>{topRightButton}</div>}
          </div>
          {topChildren && !topChildrenMiddle && <div>{topChildren}</div>}
        </div>
      )}
      <div>{body}</div>
    </DashCardContainer>
  );
};
