import React, { useCallback } from 'react';
import { HomeActionsProps } from '../../types';
import { Link } from 'react-router-dom';
import { ActButton } from './styles';
import { toast } from 'react-toastify';
import { verificarCodTela } from '../../utils/verificarCodTela';
import api from '~/services/api';

export const ButtonAction: React.FC<HomeActionsProps> = ({
  label,
  href,
  icon: Icon,
  codTela,
  user,
  buttonDestaque = false,
  onClick,
  customBgColor = 'white',
}) => {
  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const bloquearInteracao = () => {
    const divBloqueio = document.createElement('div');
    divBloqueio.id = 'bloqueio-interacao';
    divBloqueio.style.position = 'fixed';
    divBloqueio.style.top = '0';
    divBloqueio.style.left = '0';
    divBloqueio.style.width = '100%';
    divBloqueio.style.height = '100%';
    divBloqueio.style.zIndex = '9999';
    divBloqueio.style.pointerEvents = 'auto';
    document.body.appendChild(divBloqueio);

    const desabilitarTeclado = (e: any) => {
      if (
        e.key === 'Enter' ||
        e.key === ' ' ||
        e.key === 'Tab' ||
        e.key === 'Escape' ||
        e.key === 'ArrowRight'
      )
        e.preventDefault();
    };

    document.addEventListener('keydown', desabilitarTeclado);

    return () => {
      document.removeEventListener('keydown', desabilitarTeclado);
      document.body.removeChild(divBloqueio);
    };
  };

  const buscarTelaPorCodigo = useCallback(async () => {
    const { data } = await api.get(`/telasModulo/${user.user?.cod_controle}`);

    if (data.success) {
      await Promise.all(
        data.data.map(async (modulo: any) => {
          const telaEncontrada = modulo.telas.find(
            (tela: any) => tela.cod_tela === codTela,
          );

          if (!telaEncontrada || !telaEncontrada.flg_abre_pesquisa) return;

          const tabElement = document.getElementById(`${codTela}-tab`);
          const paneElement = document.getElementById(`${codTela}`);

          if (!tabElement || !paneElement) return;

          const searchButton = paneElement.querySelector<HTMLElement>(
            'button[type="button"][title="Pesquisar"] span',
          );

          const desbloquearInteracao = bloquearInteracao();

          if (searchButton) {
            searchButton.click();
            await delay(5);

            const modalContent = document.getElementById('swal2-content');
            if (modalContent) {
              modalContent.style.visibility = 'hidden';

              const confirmButton = Array.from(
                document.querySelectorAll<HTMLButtonElement>(
                  'button[type="button"]',
                ),
              ).find((button) => button.textContent?.trim() === 'Sim');

              confirmButton?.click();
            }
          }
          desbloquearInteracao();
        }),
      );
    }
  }, [codTela, user.user?.cod_controle]);

  return (
    <Link
      to={href}
      onClick={async () => {
        if (!verificarCodTela(user.user.controleAcessoTela, codTela)) {
          toast.warning(
            `Ação não habilitada para seu usuário.\nConsulte o administrador do sistema.`,
          );
          const homeTab = document.getElementById('204-tab');
          setTimeout(() => {
            if (homeTab) homeTab.click();
          }, 500);
          return;
        }
        await buscarTelaPorCodigo();
        if (onClick) onClick();
      }}
    >
      <ActButton
        type="button"
        buttonDestaque={buttonDestaque}
        customBgColor={customBgColor}
      >
        <Icon size={23} />
        {label}
      </ActButton>
    </Link>
  );
};
