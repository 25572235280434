import styled, { css, keyframes } from 'styled-components';

const shine = keyframes`
  from {
    left: -100%;
  }
  to {
    left: 100%;
  }
`;

export const ActButton = styled.button<{
  buttonDestaque?: boolean;
  customBgColor?: string;
}>`
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  color: white;

  padding: 0.625rem;
  height: 3.75rem;
  width: 100%;
  background-color: ${(props) =>
    props.customBgColor ? props.customBgColor : 'white'};

  text-align: center;

  svg {
    margin-right: 0.3125rem;
  }

  &:hover,
  &:focus,
  &:active {
    box-shadow: rgba(0, 0, 0, 0.5) inset 0 0 42px -23px;
    border-color: rgba(0, 0, 0, 0.75);
  }

  ${(props) =>
    props.buttonDestaque &&
    css`
      background-color: rgb(87 6 158) !important;

      position: relative;
      overflow: hidden;
      box-shadow: rgba(0, 0, 0, 0.3) 7px 7px 13px 3px !important;
      border: 2px solid rgba(0, 0, 0, 0) !important;

      &:hover,
      &:focus,
      &:active {
        border-color: rgba(0, 0, 0, 0.75) !important;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          120deg,
          rgba(255, 255, 255, 0) 30%,
          rgba(255, 255, 255, 0.5) 50%,
          rgba(255, 255, 255, 0) 70%
        );
        animation: ${css`
          ${shine} 3.0s infinite linear
        `};
      }
    `}
`;
