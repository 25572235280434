import React, { useCallback, useContext } from 'react';
import { ContainerComum } from '../styles';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useDashboard } from '~/pages/Dashboard/hooks/useDashboard';
import { Link } from 'react-router-dom';
import { FilterContext } from '~/context/filter';
import { toast } from 'react-toastify';
import { nanoid } from 'nanoid';

type EstoqueStatus = 'Sem estoque' | 'Com estoque' | 'Estoque baixo';

const COLORS: Record<EstoqueStatus, string> = {
  'Sem estoque': '#FF6361',
  'Com estoque': '#0088FE',
  'Estoque baixo': '#FF8042',
};

export const BodyProdutos: React.FC = () => {
  const { dashboardData } = useDashboard();
  const { AddFilterOrUpdate, RemoveFilter, filtersContexto } =
    useContext(FilterContext);

  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const arrProduto = dashboardData?.produtos || [];

  const data = arrProduto.flatMap((produto) => [
    { name: 'Sem estoque', value: Number(produto.qtd_sem_estoque) },
    { name: 'Com estoque', value: Number(produto.qtd_com_estoque) },
    { name: 'Estoque baixo', value: Number(produto.qtd_baixo_estoque) },
  ]);

  const codTela = 28;

  const createFilter = useCallback(
    (
      codCampo: number,
      codTipoDados: number,
      codTipoInfo: number | null,
      condicao: number,
      desNome: string,
      desValor: number,
      listagem_info: null | string,
      nomeBd: string,
      tipoExpressao: number,
    ) => ({
      codTela,
      edit: false,
      flg_pesquisado: false,
      name_card: '',
      cor: '',
      itens: [
        {
          cod_campo: codCampo,
          cod_tipo_dados: codTipoDados,
          cod_tipo_info: codTipoInfo,
          condicao,
          des_nome: desNome,
          des_valor: desValor,
          expressao_validacao: null,
          index: nanoid(),
          isBlock: false,
          listagem_info,
          nome_bd: nomeBd,
          tipo_expressao: tipoExpressao,
        },
      ],
    }),
    [codTela],
  );

  const onClick = useCallback(async () => {
    await delay(200);

    const idTab = `${codTela}-tab`;
    const tab = document.getElementById(idTab);

    if (!tab) return;

    const labelTela = 'Cadastro de Produto';
    const h3Elements = document.querySelectorAll('h3');

    const element = Array.from(h3Elements).find(
      (el) => el.textContent?.trim() === labelTela,
    );

    if (element) {
      toast.warning('Finalize esta ação para prosseguir');
      return;
    }

    const divPane = document.getElementById(`${codTela}`);
    if (!divPane) return;

    document.body.style.pointerEvents = 'none';

    const filtersToRemove = [14087];
    const existingFilter = filtersContexto.find(
      (filter) => filter.codTela === codTela,
    );

    if (existingFilter) {
      existingFilter.itens
        .filter((filter) => filtersToRemove.includes(filter.cod_campo))
        .forEach((f) => RemoveFilter(codTela, f.index));
    }

    await delay(200);

    const applyFilters = () => {
      const filters = [];

      const listagemInfo = `[
        {
          "cod_item": 1,
          "valor": "0.000,000",
          "des_item": "QUANTIDADE"
        }
      ]`;

      filters.push(
        createFilter(
          14087,
          4,
          116,
          2,
          'Qtd. Estoque Lj',
          10,
          listagemInfo,
          't.qtd_estoque',
          1,
        ),
      );

      filters.forEach(AddFilterOrUpdate);
    };

    applyFilters();

    const buttonPesquisar: HTMLElement | null = divPane.querySelector(
      'button[type="button"][title="Pesquisar"] span',
    );

    if (buttonPesquisar) {
      await delay(500);
      buttonPesquisar.click();
    }

    await delay(200);
    document.body.style.pointerEvents = 'auto';
  }, [AddFilterOrUpdate, RemoveFilter, createFilter, filtersContexto]);

  return (
    <ContainerComum gap="0rem">
      <ResponsiveContainer width="100%" height={235}>
        <PieChart>
          <Pie
            data={data}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={70}
            fill="#8884d8"
            label
          >
            {data.map((entry: any) => (
              <Cell
                key={`cell-${entry.name}`}
                fill={COLORS[entry.name as EstoqueStatus]}
              />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      <div className="qtd-card-produtos">
        <Link to="/mix-de-produto">
          <span>{arrProduto[0]?.qtd_total || 0} Ativos</span>
        </Link>
        <Link
          to="/produto"
          onClick={async () => {
            await onClick();
          }}
        >
          <span>{arrProduto[0]?.qtd_baixo_estoque || 0} Estq. Baixo</span>
        </Link>
      </div>
    </ContainerComum>
  );
};
