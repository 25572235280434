import React, { useCallback, useContext } from 'react';
import { ContainerComum } from '../styles';
import { moneyFormat } from '~/utils/functions';
import { useDashboard } from '~/pages/Dashboard/hooks/useDashboard';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { BsCartPlusFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { FilterContext } from '~/context/filter';
import { nanoid } from 'nanoid';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

export const BodyPagarReceber: React.FC<{ card: any }> = ({ card }) => {
  const { dashboardData } = useDashboard();
  const { AddFilterOrUpdate, RemoveFilter, filtersContexto } =
    useContext(FilterContext);

  const delay = (ms: number): Promise<void> => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  const color = card === 'pagar' ? '#CF2A28' : '#2A78E4';
  const label = card === 'pagar' ? 'Pago hoje:' : 'Receb. hoje:';
  const Icon =
    card === 'pagar' || card === 'receber' ? FaRegMoneyBillAlt : BsCartPlusFill;

  const arr =
    card === 'pagar'
      ? dashboardData?.contasAPagar
      : dashboardData?.contasAReceber;

  const openToday =
    card === 'pagar' ? arr?.[0]?.val_aberto || 0 : arr?.[0]?.val_aberto || 0;
  const closeToday =
    card === 'pagar' ? arr?.[0]?.val_quitado || 0 : arr?.[0]?.val_quitado || 0;

  const codTela = card === 'pagar' ? 51 : 46;

  const createFilter = useCallback(
    (
      codCampo: number,
      codTipoDados: number,
      codTipoInfo: number | null,
      desNome: string,
      desValor: string,
      nomeBd: string,
    ) => ({
      codTela,
      edit: false,
      flg_pesquisado: false,
      name_card: '',
      cor: '',
      itens: [
        {
          cod_campo: codCampo,
          cod_tipo_dados: codTipoDados,
          cod_tipo_info: codTipoInfo,
          condicao: 0,
          des_nome: desNome,
          des_valor: desValor,
          expressao_validacao: null,
          index: nanoid(),
          isBlock: false,
          listagem_info: null,
          nome_bd: nomeBd,
          tipo_expressao: 0,
        },
      ],
    }),
    [codTela],
  );

  const onClick = useCallback(
    async (tipo: 'hoje' | 'pago-hoje') => {
      await delay(200);

      const idTab = `${codTela}-tab`;
      const tab = document.getElementById(idTab);

      if (!tab) return;

      const labelTela =
        card === 'pagar' ? 'Contas a Pagar' : 'Contas a Receber';
      const h3Elements = document.querySelectorAll('h3');

      const element = Array.from(h3Elements).find(
        (el) => el.textContent?.trim() === labelTela,
      );

      if (element) {
        toast.warning('Finalize esta ação para prosseguir');
        return;
      }

      const divPane = document.getElementById(`${codTela}`);
      if (!divPane) return;

      document.body.style.pointerEvents = 'none';

      const filtersToRemove = [12193, 12196, 12197, 13925, 12233];
      const existingFilter = filtersContexto.find(
        (filter) => filter.codTela === codTela,
      );

      if (existingFilter) {
        existingFilter.itens
          .filter((filter) => filtersToRemove.includes(filter.cod_campo))
          .forEach((f) => RemoveFilter(codTela, f.index));
      }

      await delay(200);

      const applyFilters = () => {
        const filters = [];

        if (card === 'pagar') {
          if (tipo === 'hoje') {
            filters.push(
              createFilter(
                12193,
                1,
                1,
                'Data do Vencimento',
                '1',
                't.dta_vencimento',
              ),
              createFilter(
                12196,
                6,
                null,
                'Quitado?',
                'false',
                't.flg_quitado',
              ),
            );
          } else if (tipo === 'pago-hoje') {
            filters.push(
              createFilter(
                12197,
                1,
                1,
                'Data da Quitação',
                '1',
                't.dta_quitada',
              ),
            );
          }
        } else if (tipo === 'hoje') {
          const currDate = format(new Date(), 'yyyy-MM-dd');
          filters.push(
            createFilter(
              13925,
              1,
              null,
              'Data Vencimento',
              currDate,
              't.dta_vencimento',
            ),
            createFilter(12233, 6, null, 'Quitado?', 'false', 't.flg_quitado'),
          );
        } else if (tipo === 'pago-hoje') {
          filters.push(
            createFilter(12197, 1, 1, 'Data da Quitação', '1', 't.dta_quitada'),
          );
        }

        filters.forEach(AddFilterOrUpdate);
      };

      applyFilters();

      const buttonPesquisar: HTMLElement | null = divPane.querySelector(
        'button[type="button"][title="Pesquisar"] span',
      );

      if (buttonPesquisar) {
        await delay(500);
        buttonPesquisar.click();
      }

      await delay(200);
      document.body.style.pointerEvents = 'auto';
    },
    [
      AddFilterOrUpdate,
      RemoveFilter,
      card,
      codTela,
      createFilter,
      filtersContexto,
    ],
  );

  return (
    <ContainerComum>
      <Link
        to={card === 'pagar' ? '/contas-a-pagar' : '/contas-a-receber'}
        onClick={async () => {
          await onClick('hoje');
        }}
        style={{ backgroundColor: 'white', borderRadius: '5px' }}
      >
        <div className="first-content">
          <Icon size={25} color={color} />
          Hoje:
          <strong style={{ color }}>
            R$ {moneyFormat(openToday.toFixed(2))}
          </strong>
        </div>
      </Link>
      <Link
        to={card === 'pagar' ? '/contas-a-pagar' : '/contas-a-receber'}
        onClick={async () => {
          await onClick('pago-hoje');
        }}
        style={{ backgroundColor: 'white', borderRadius: '5px' }}
      >
        <div className="second-content">
          {label}
          <strong>R$ {moneyFormat(closeToday.toFixed(2))}</strong>
        </div>
      </Link>
    </ContainerComum>
  );
};
