import React from 'react';
import { ContainerComum } from '../styles';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
} from 'recharts';
import { useDashboard } from '~/pages/Dashboard/hooks/useDashboard';
import { Link } from 'react-router-dom';
import useAuth from '~/hooks/useAuth';
import { toast } from 'react-toastify';
import { verificarCodTela } from '~/pages/Dashboard/utils/verificarCodTela';

export const BodyCaixaHoje: React.FC = () => {
  const { dashboardData } = useDashboard();
  const user = useAuth();

  const arrCaixasHoje = dashboardData?.caixasHoje || [];
  const dataCaixasHoje = arrCaixasHoje.flatMap((item) => [
    {
      name: String(item.num_pdv),
      Total: item.val_total,
    },
  ]);

  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <ContainerComum>
      <Link
        to="/conferencia-venda-sat-nfce"
        onClick={() => {
          if (!verificarCodTela(user.user.controleAcessoTela, 289)) {
            toast.warning(
              `Ação não habilitada para seu usuário.\nConsulte o administrador do sistema.`,
            );
            const homeTab = document.getElementById('204-tab');
            setTimeout(() => {
              if (homeTab) homeTab.click();
            }, 500);
          }
        }}
      >
        <ResponsiveContainer width="100%" height={451}>
          <>
            {dataCaixasHoje.length === 0 && (
              <p
                style={{
                  textAlign: 'center',
                  fontSize: '18px',
                }}
              >
                Nenhum registro encontrado.
              </p>
            )}
            {dataCaixasHoje.length > 0 && (
              <BarChart width={270} height={460} data={dataCaixasHoje}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                {/* <YAxis tickFormatter={formatCurrency} /> */}
                <Tooltip formatter={(value: number) => formatCurrency(value)} />
                <Legend />
                <Bar
                  dataKey="Total"
                  fill="#8884d8"
                  activeBar={<Rectangle fill="#7671d4" />}
                />
              </BarChart>
            )}
          </>
        </ResponsiveContainer>
      </Link>
    </ContainerComum>
  );
};
