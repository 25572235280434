import React, {
  ComponentType,
  createElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { ActGridContainer, Container } from './styles';
import Separator from '~/components/Separator';
import { homeActions } from './utils/home-actions';
import { ButtonAction } from './Components/ButtonAction';
import { BtnUpdateAll } from './Components/BtnUpdateAll';
import { Card } from './Components/DashCard/Card';
import { DashButton } from './Components/DashCard/Button';
import { DashBody } from './Components/DashCard/Body/DashBody';
import { useDashboard } from './hooks/useDashboard';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { format } from 'date-fns';
import useAuth from '~/hooks/useAuth';
import { homeLinks } from './utils/home-links';
import { nanoid } from 'nanoid';
import { ModalMoreAct } from './Components/ModalMoreAct';
import { BiPlus } from 'react-icons/bi';
import { formatTimeBR } from './utils/formatTimeBR';

export const DashboardContent: React.FC<{ screen: any[] }> = ({ screen }) => {
  const { loading, handleUpdateDataDashboard, loadingQry } = useDashboard();
  const [openModalMoreAct, setOpenModalMoreAct] = useState(false);
  const [lastUpdatedTime, setLastUpdatedTime] = useState<string>(
    formatTimeBR(),
  );

  useEffect(() => {
    if (loading.ocorrencias || loadingQry) setLastUpdatedTime(formatTimeBR());
  }, [loading.ocorrencias, loadingQry]);

  const onCloseModalMoreAct = useCallback(() => {
    setOpenModalMoreAct(false);
  }, []);

  const user = useAuth();

  useEffect(() => {
    handleUpdateDataDashboard('todos');
  }, []);

  return (
    <Container>
      <section className="section-home-links">
        {homeLinks.map((link) => (
          <a
            key={nanoid()}
            href={link.link}
            className="container-links"
            target="_blank"
            rel="noopener noreferrer"
          >
            {link.iconNode && (
              <div className="icon-container">
                {createElement(link.iconNode as ComponentType)}
              </div>
            )}
            {link.label}
          </a>
        ))}
      </section>
      <section>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span style={{ color: '#bdbdbd' }}>Ações</span>
          <span
            style={{
              flexGrow: 1,
              borderBottom: '0.0625rem solid #ededed',
              margin: '0 0.625rem',
            }}
          />
          <button
            onClick={() => setOpenModalMoreAct(true)}
            style={{
              backgroundColor: 'transparent',
              borderColor: 'transparent',
            }}
            type="button"
            aria-label="button-open-modal"
          >
            <BiPlus size={23} color="#009e11" /> Mais ações
          </button>
        </div>
        <ActGridContainer>
          {homeActions.map((action) => (
            <ButtonAction
              key={action.label}
              label={action.label}
              href={action.href}
              icon={action.icon}
              codTela={action.codTela}
              user={user}
              buttonDestaque={action.buttonDestaque}
              customBgColor={action.customBgColor || 'white'}
            />
          ))}
        </ActGridContainer>
      </section>
      <section className="section-dashboard">
        <Separator labelText="Dashboard" marginTop="0" childrenWidth="auto">
          <BtnUpdateAll />
        </Separator>
        <div className="content-dashboard">
          <div className="left-side">
            <div className="cards-top-left">
              <Card
                title="A pagar"
                topRightButton={<DashButton tipo="add" url="/contas-a-pagar" />}
                body={<DashBody card="pagar" />}
                bgColor=" #DD4B39"
              />
              <Card
                title="A receber"
                topRightButton={
                  <DashButton tipo="add" url="/contas-a-receber" />
                }
                body={<DashBody card="receber" />}
                bgColor=" #00C0EF"
              />
              <Card
                title="Venda"
                topRightButton={<DashButton tipo="add" url="/pdv-online" />}
                body={<DashBody card="venda" />}
                bgColor="#00A65A"
              />
            </div>
            <div className="cards-bottom-left">
              <Card
                title="Venda"
                topChildren={
                  <DashButton tipo="requisito" tipoBusca="venda-grafico" />
                }
                body={<DashBody card="venda-grafico" />}
                bgColor="white"
              />
              <Card
                title="Caixas Hoje"
                topRightButton={
                  <DashButton
                    tipo="refresh"
                    tipoBusca="caixas-hoje"
                    isDisabled={loading.caixas_hoje || loadingQry}
                    svgColor="#009e11"
                  />
                }
                body={<DashBody card="caixas-hoje" />}
                bgColor="white"
              />
            </div>
          </div>
          <div className="right-side">
            <Card
              title="Ocorrências"
              topRightButton={
                <DashButton
                  tipo="refresh"
                  tipoBusca="ocorrencias"
                  isDisabled={loading.ocorrencias || loadingQry}
                />
              }
              topChildren={
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    color: 'white',
                  }}
                >
                  <FaRegCalendarAlt size={19} />
                  <span style={{ marginLeft: '0.3125rem' }}>
                    {format(new Date(), 'dd/MM/yyyy')} às {lastUpdatedTime}
                  </span>
                </div>
              }
              topChildrenMiddle={false}
              body={<DashBody card="ocorrencias" screen={screen} />}
              bgColor="#F39C12"
            />
            <Card
              title="Produtos"
              topRightButton={
                <DashButton tipo="add" url="/produto" svgColor="#009e11" />
              }
              body={<DashBody card="produtos" />}
              bgColor="white"
            />
          </div>
        </div>
      </section>
      <ModalMoreAct open={openModalMoreAct} onClose={onCloseModalMoreAct} />
    </Container>
  );
};
