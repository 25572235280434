import React from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import {
  ButtonDash,
  ButtonGraficoVenda,
  TipoRequisitoContainer,
} from './styles';
import { Link } from 'react-router-dom';
import { useDashboard } from '~/pages/Dashboard/hooks/useDashboard';
import { TipoBuscaQry } from '~/pages/Dashboard/types';
import { CircularProgress } from '@material-ui/core';
import { MdOutlineOpenInNew } from 'react-icons/md';

interface ButtonProps {
  tipo: 'add' | 'refresh' | 'requisito';
  url?: string;
  tipoBusca?: TipoBuscaQry;
  isDisabled?: boolean;
  svgColor?: string;
}

export const DashButton: React.FC<ButtonProps> = ({
  tipo,
  url = '',
  tipoBusca,
  isDisabled = false,
  svgColor = 'white',
}) => {
  const {
    handleUpdateDataDashboard,
    loadingQry,
    handleRequisitoVenda,
    requisitoVenda,
    loading,
    tipoGraficoVenda,
    handleTipoGraficoVenda,
  } = useDashboard();

  const Icon = tipo === 'add' ? MdOutlineOpenInNew : FiRefreshCw;

  if (tipo === 'add' && url) {
    return (
      <Link to={url}>
        <ButtonDash
          svgColor={svgColor}
          aria-label={`botão ${tipo}`}
          type="button"
        >
          <Icon size={25} />
        </ButtonDash>
      </Link>
    );
  }

  if (tipo === 'refresh') {
    return (
      <ButtonDash
        svgColor={svgColor}
        aria-label={`botão ${tipo} ${tipoBusca ?? ''}`}
        type="button"
        onClick={async () => {
          if (loadingQry || isDisabled) return;
          if (tipoBusca) await handleUpdateDataDashboard(tipoBusca);
        }}
        disabled={loadingQry || isDisabled}
      >
        {isDisabled && <CircularProgress size={25} />}
        {!isDisabled && <Icon size={25} />}
      </ButtonDash>
    );
  }

  if (tipo === 'requisito') {
    const renderButtonGraficoVenda = (
      label: string,
      tipoGrafico: string,
      handleClick: any,
      activeType: any,
    ) => (
      <ButtonGraficoVenda
        type="button"
        disabled={loading.vendas_grafico || loadingQry}
        onClick={() => handleClick(tipoGrafico)}
        style={{
          backgroundColor:
            activeType === tipoGrafico ? '#DDCDEC' : 'transparent',
        }}
      >
        {label}
      </ButtonGraficoVenda>
    );

    return (
      <TipoRequisitoContainer>
        <div className="content-button-tipo">
          {renderButtonGraficoVenda(
            'Faturamento',
            'Fat',
            handleTipoGraficoVenda,
            tipoGraficoVenda.tipo,
          )}
          {renderButtonGraficoVenda(
            'Meio Pgto',
            'MeioPgto',
            handleTipoGraficoVenda,
            tipoGraficoVenda.tipo,
          )}
          {renderButtonGraficoVenda(
            'Depto',
            'Depto',
            handleTipoGraficoVenda,
            tipoGraficoVenda.tipo,
          )}
        </div>
        <div className="content-button-requisito">
          {renderButtonGraficoVenda(
            'Hoje',
            'hoje',
            handleRequisitoVenda,
            requisitoVenda.requisito,
          )}
          {renderButtonGraficoVenda(
            'Semana',
            'semana',
            handleRequisitoVenda,
            requisitoVenda.requisito,
          )}
          {renderButtonGraficoVenda(
            'Mês',
            'mes',
            handleRequisitoVenda,
            requisitoVenda.requisito,
          )}
        </div>
      </TipoRequisitoContainer>
    );
  }

  return <></>;
};
