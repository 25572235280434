import React, { useCallback, useContext } from 'react';
import { useDashboard } from '~/pages/Dashboard/hooks/useDashboard';
import { ContainerComum } from '../styles';
import { Link, useHistory } from 'react-router-dom';
import useAuth from '~/hooks/useAuth';
import { toast } from 'react-toastify';
import { verificarCodTela } from '~/pages/Dashboard/utils/verificarCodTela';
import { InformacaoTelasAcessadasContext } from '~/context/InformacaoTelasAcessadas';
import api from '~/services/api';
import { FilterContext } from '~/context/filter';
import { nanoid } from 'nanoid';

interface PendingData {
  tipo_nf: number;
  qtd_nfe_pendente: number;
}

interface InfoBlockProps {
  label: string;
  path: string;
  value: number;
  onClick?: () => void;
}

type FilterItem = {
  cod_campo: number;
  cod_tipo_dados: number;
  cod_tipo_info: number | null;
  condicao: number;
  des_nome: string;
  des_valor: string;
  expressao_validacao: any;
  index: string;
  isBlock: boolean;
  listagem_info: any;
  nome_bd: string;
  tipo_expressao: any;
};

type Filter = {
  codTela: number;
  edit: boolean;
  flg_pesquisado: boolean;
  name_card: string;
  cor: string;
  itens: FilterItem[];
};

const InfoBlock: React.FC<InfoBlockProps> = ({
  label,
  path,
  value,
  onClick,
}) => (
  <Link
    to={path}
    onClick={onClick}
    style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', borderRadius: '5px' }}
  >
    <div className="first-content">
      {label}: <strong style={{ color: '#CF2D30' }}>{value}</strong>
    </div>
  </Link>
);

const delay = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const exibirMensagemRestricao = (mensagem: string, homeTabId: string) => {
  toast.warning(mensagem);
  const homeTab = document.getElementById(homeTabId);
  if (homeTab) {
    setTimeout(() => homeTab.click(), 500);
  }
};

const LABELS_TELA: Record<number, string> = {
  47: 'Emissão Cliente',
  60: 'Entrada de NF-e de Fornecedor',
  172: 'Lan. NF de Outras Operações',
  203: "Devolução e Saída de NF's de Fornecedor",
};

const FILTERS_MAP: Record<number, number> = {
  47: 14064,
  60: 14276,
  172: 14280,
  203: 14264,
};

export const BodyOcorrencias: React.FC<{ screen?: any[] }> = ({ screen }) => {
  const { dashboardData } = useDashboard();
  const user = useAuth();
  const history = useHistory();
  const { addScreenInfo } = useContext(InformacaoTelasAcessadasContext);
  const { AddFilterOrUpdate, RemoveFilter, filtersContexto } =
    useContext(FilterContext);

  const getPendingData = (key: string) => {
    const data: any = dashboardData;
    const item: any = data?.[key];
    return item || [];
  };

  const nfePendentes: PendingData[] = getPendingData('nfePendentes');
  const cuponsTransmitir = getPendingData('cuponsTransmitir');
  const produtosCarga = getPendingData('produtosCarga');

  const nfeTypes = {
    compras: nfePendentes.find((item) => item.tipo_nf === 0),
    venda: nfePendentes.find((item) => item.tipo_nf === 1),
    devolucao: nfePendentes.find((item) => item.tipo_nf === 3),
    outrasOp: nfePendentes.find((item) => item.tipo_nf === 6),
  };

  const blocks = [
    {
      label: 'Cupons p/ Transmitir',
      path: '/pedido-venda',
      value: cuponsTransmitir[0]?.qtd_cupom_pendente || 0,
      codTela: 302,
    },
    {
      label: 'NFe Pendente (Venda)',
      path: '/emissao-nfe',
      value: nfeTypes.venda?.qtd_nfe_pendente || 0,
      codTela: 47,
    },
    {
      label: 'NFe Pendente (Compras)',
      path: '/entrada-de-nfe',
      value: nfeTypes.compras?.qtd_nfe_pendente || 0,
      codTela: 60,
    },
    {
      label: 'NFe Pendente (Outras Op.)',
      path: '/lancamento-nf-de-outras-operacoes',
      value: nfeTypes.outrasOp?.qtd_nfe_pendente || 0,
      codTela: 172,
    },
    {
      label: 'NFe Pendente (Devol.)',
      path: '/devolucao-e-saida-nf-forn',
      value: nfeTypes.devolucao?.qtd_nfe_pendente || 0,
      codTela: 203,
    },
  ];

  const handleProdutoCargaClick = async () => {
    if (
      !verificarCodTela(user.user.controleAcessoTela, 44) &&
      !verificarCodTela(user.user.controleAcessoTela, 296)
    ) {
      exibirMensagemRestricao(
        `Ação não habilitada para seu usuário.\nConsulte o administrador do sistema.`,
        '204-tab',
      );
      return;
    }

    if (!verificarCodTela(user.user.controleAcessoTela, 296)) {
      exibirMensagemRestricao(
        `Ação para a tela de "Impressão de Etiqueta" não habilitada para seu usuário.\nConsulte o administrador do sistema.`,
        '204-tab',
      );
    }

    if (verificarCodTela(user.user.controleAcessoTela, 296)) {
      if (screen) {
        const telaImpressao = screen.find(
          (s) => s.url === 'impressao-etiqueta' && s.id === 296,
        );
        if (telaImpressao) {
          const { data } = await api.post('/tela-por-codigo', {
            cod_tela: telaImpressao.id,
          });
          if (data.data && data.success) {
            const tela = {
              cod_tela: telaImpressao.id,
              ...telaImpressao,
              ...data.data[0],
            };
            addScreenInfo(tela);
          }
          await delay(300);
          history.push(telaImpressao.url);
        }
      }
    }

    if (!verificarCodTela(user.user.controleAcessoTela, 44)) {
      exibirMensagemRestricao(
        `Ação para a tela de "Carga" não habilitada para seu usuário.\nConsulte o administrador do sistema.`,
        '204-tab',
      );
    }
  };

  const createFilter = useCallback(
    (
      codCampo: number,
      codTipoDados: number,
      codTipoInfo: number | null,
      desNome: string,
      desValor: string,
      nomeBd: string,
      codTela: number,
    ): Filter => ({
      codTela,
      edit: false,
      flg_pesquisado: false,
      name_card: '',
      cor: '',
      itens: [
        {
          cod_campo: codCampo,
          cod_tipo_dados: codTipoDados,
          cod_tipo_info: codTipoInfo,
          condicao: 0,
          des_nome: desNome,
          des_valor: desValor,
          expressao_validacao: null,
          index: nanoid(),
          isBlock: false,
          listagem_info: null,
          nome_bd: nomeBd,
          tipo_expressao: null,
        },
      ],
    }),
    [],
  );

  const removeUnwantedFilters = (codTela: number) => {
    const filtersToRemove = [14064, 14276, 14280, 14264];
    const existingFilter = filtersContexto.find(
      (filter) => filter.codTela === codTela,
    );

    if (existingFilter) {
      existingFilter.itens
        .filter((filter) => filtersToRemove.includes(filter.cod_campo))
        .forEach((f) => RemoveFilter(codTela, f.index));
    }
  };

  const applyFilters = (codTela: number) => {
    const codCampo = FILTERS_MAP[codTela];
    if (!codCampo) return;

    const filter = createFilter(
      codCampo,
      6,
      null,
      'NFe Pendente?',
      'true',
      't.flg_nfe_pendente',
      codTela,
    );

    AddFilterOrUpdate(filter);
  };

  const handlePermissionCheck = async (
    allowed: boolean,
    tabId: string,
    codTela: number,
  ) => {
    if (!allowed) {
      exibirMensagemRestricao(
        'Ação não habilitada para seu usuário.\nConsulte o administrador do sistema.',
        tabId,
      );
      return;
    }

    await delay(200);

    const tab = document.getElementById(`${codTela}-tab`);
    if (!tab) return;

    const label = LABELS_TELA[codTela];
    const hasPendingAction = Array.from(document.querySelectorAll('h3')).some(
      (el) => el.textContent?.trim() === label,
    );

    if (hasPendingAction) {
      toast.warning('Finalize esta ação para prosseguir');
      return;
    }

    const divPane = document.getElementById(`${codTela}`);
    if (!divPane) return;

    document.body.style.pointerEvents = 'none';

    removeUnwantedFilters(codTela);

    await delay(200);

    applyFilters(codTela);

    const searchButton = divPane.querySelector<HTMLElement>(
      'button[type="button"][title="Pesquisar"] span',
    );

    if (searchButton) {
      await delay(500);
      searchButton.click();
    }

    await delay(200);
    document.body.style.pointerEvents = 'auto';
  };

  return (
    <ContainerComum>
      {blocks.map(({ label, path, value, codTela }) => (
        <InfoBlock
          key={label}
          label={label}
          path={path}
          value={value}
          onClick={async () => {
            await handlePermissionCheck(
              verificarCodTela(user.user.controleAcessoTela, codTela),
              '204-tab',
              codTela,
            );
          }}
        />
      ))}

      <InfoBlock
        label="Produtos para carga"
        path="/carga"
        value={produtosCarga[0]?.total || 0}
        onClick={handleProdutoCargaClick}
      />
    </ContainerComum>
  );
};
